import React from "react";
import { useState, useMemo, useEffect } from "react";
import Loading from "../utils/Loading";
import CustomTable from "../CustomTable";
import FormModal from "../FormModal";
import RecordCount from "../utils/RecordCount";
import { Form, Button } from "react-bootstrap";


const Attestations = (props) => {
  const [data, setData] = useState(useMemo(() => props.attestations, []));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!data) {
      const fetchData = async () => {
        try {
          const response = await fetch(props.get_attestations_path, { headers: { Accept: "application/json" } });
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const fetchedData = await response.json();
          setData(fetchedData);
        } catch (error) {
          console.log("Error fetching data: ", error);
        } finally {
          setIsLoading(false);
        }
      };
      if (!data || data.length === 0) {
        fetchData();
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, []);

  const [modalId, setModalId] = useState(null);
  const [showModalComplete, setShowModalComplete] = useState(false);
  const [showModalDenied, setShowModalDenied] = useState(false);
  const [showModalArchive, setShowModalArchive] = useState(false);

  const columns = useMemo(
    () => [  
      {
        Header: "Covered Entity",
        accessor: "id_340b",
        filter: "fuzzyText",
        Cell: (row) => (
          <>
            <a href={row.row.original.link}>
              <b>{row.row.original.entity_name}</b>
            </a>
            <br/>
            <a href={`/entities/340bid/${row.value}`}>
              <small className="text-muted">{row.value}</small>
            </a>
          </>
        )
      },
      {
        Header: "Manufacturer",
        accessor: "manufacturer",
        filter: "fuzzyText",
        Cell: (row) => <small>{row.value}</small>
      },
      {
        Header: "Policy",
        accessor: "policy",
        filter: "fuzzyText",
        Cell: (row) => <small className="text-muted">CP Anti-Diversion</small>
      },
      {
        Header: "Due Date",
        accessor: "due_at",
        filter: "fuzzyText",
        Cell: (row) => <small className="text-muted">{row.value}</small>
      },
      { 
        Header: "Items",
        accessor: "attestations",
        Cell: (row) => <>
         {row.row.original.completed_attestations_count > 0 ?
            <div className="badge bg-success me-1" title="Completed">
              {row.row.original.completed_attestations_count}
            </div> 
          : null }
          { row.row.original.in_review_attestations_count > 0 ? 
            <div className="badge bg-warning me-1" title="In Review">
              {row.row.original.in_review_attestations_count}
            </div>
          : null }
          { row.row.original.denied_attestations_count > 0 ?
            <div className="badge bg-danger me-1" title="Denied">
              {row.row.original.denied_attestations_count}
            </div>
          : null }
          { row.row.original.not_started_attestations_count > 0 ?
            <div className="badge bg-white border text-muted" title="Not Started">
              {row.row.original.not_started_attestations_count}
            </div>
          : null }
        </>
      },      
      {
        Header: "Review",
        accessor: "link",
        Cell: (row) => (
          <a href={row.value} className="btn btn-primary">Review</a>
        ),
      },
    ],
    []
  );

  const AttestationPrompt = (prompt_props) => {
    return (      
      <React.Fragment>
        <div className="bg-body-tertiary p-4 rounded-4 shadow-sm border">
          <div className="row">
            <div className="col-sm-6">
              <small>
                Submitted At:<br/>
                <b>{prompt_props.attestation.submitted_at}</b>
              </small>
            </div>
            <div className="col-sm-6">
              <small>
                Due At:<br/>
                <b>{prompt_props.attestation.due_at}</b>
              </small>                
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-6">
              <small>
                Manufacturer:<br/>
                <b>{prompt_props.attestation.manufacturer}</b>
              </small>
            </div>
            <div className="col-sm-6">
              <small>
                Covered Entity:<br/>
                <b>{prompt_props.attestation.entity_name}</b>
              </small>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-6">
              <small>
                340B ID:<br/>
                <b>{prompt_props.attestation.id_340b}</b>
              </small>                
            </div>
            <div className="col-sm-6">
              <small>
                Pharmacy ID:<br/>
                <b>{prompt_props.attestation.pharmacy_id}</b>
              </small>                
            </div>
          </div>
        </div>
        <div className="mt-4">
          { prompt_props.role == "deny" ?
            <Form.Group className="mb-3" controlId="denial_reason">
              <p>Please confirm your reason to <b className="text-danger">Deny</b> this attestation.</p>
              <p><b>Note:</b> This will be displayed in an email to the covered entity's contacts.</p>
              <div className="bg-body-tertiary rounded-4 p-4 border">
                <Form.Label><b className="text-danger">Denial Reason:</b> <small className="text-muted">(required)</small></Form.Label>
                <Form.Control required as="textarea" name="attestation[denial_reason]" placeholder="Enter your denial reason..." />
              </div>              
            </Form.Group>
          : 
          <p>Please confirm that you wish to <b className="text-success">ACCEPT</b> this attestation.</p>
          }
        </div>
      </React.Fragment>
    );
  };

  const CsvDownload = (props) => {
    return (
      <div className="bg-body-tertiary p-2 rounded-4 mb-3 text-center">
        <small><b>{props.attestations_count}</b> record(s) found. </small>
        <a href={props.download_link} className="btn btn-sm bg-white border ms-2">
          <i className="fa fa-download me-2"></i>
          Export (.csv)
        </a>
      </div>
    )
  }


  return (
    <React.Fragment>
      {isLoading ?
        <Loading />
      : <>
        { data.length > 0 ?
          <>
            <CsvDownload attestations_count={data.length} download_link={props.export_attestations_path} />
            <CustomTable columns={columns} data={data} /> 
          </>
        : 
          <div className="bg-body-tertiary p-4 rounded-4 text-center text-muted">
            <i>No results found.</i>
          </div>
        }
          <FormModal
              show={showModalComplete}
              title="Complete Attestation"
              buttonText="Complete Attestation"
              buttonType="success"
              modalId={`complete_attestation_modal_${modalId}`}
              fields={<AttestationPrompt attestation={data.find((row) => row.id == modalId)} role="complete" />}
              method="POST"
              action={`/attestations/${data.find((row) => row.id == modalId)?.id}/complete`}
              dismiss={() => {
                setShowModalComplete(false);
              }}
            />
            <FormModal
              show={showModalDenied}
              title="Deny Attestation"
              buttonText="Deny Attestation"
              buttonType="danger"
              modalId={`deny_attestation_modal${modalId}`}
              fields={<AttestationPrompt attestation={data.find((row) => row.id == modalId)} role="deny" />}
              method="POST"
              action={`/attestations/${data.find((row) => row.id == modalId)?.id}/deny`}
              dismiss={() => {
                setShowModalDenied(false);
              }}
            />
            <FormModal
              show={showModalArchive}
              title="Archive Attestation"
              buttonText="Archive Attestation"
              buttonType="danger"
              modalId={`archive_attestation_modal${modalId}`}
              fields={<AttestationPrompt attestation={data.find((row) => row.id == modalId)} role="archive" />}
              method="POST"
              action={`/attestations/${data.find((row) => row.id == modalId)?.id}/archive`}
              dismiss={() => {
                setShowModalArchive(false);
              }}
            />            
        </>
      }            
    </React.Fragment>
  );
};

export default Attestations;
